import React, { Component } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import Img from "gatsby-image"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import ArrowFwdIosTwoToneIcon from '@material-ui/icons/ArrowForwardIosTwoTone';


class PhotoGrid extends Component {
	constructor(props){
		super(props)
		this.state = {
			highlightedPhoto:0,
			showHighlighted:false
		}
	}

	highlightImage = (key) => {
		console.log(key)
		this.setState({
			showHighlighted:true,
			highlightedPhoto: key
		})
	}

	unHighlightImage = () => {
		this.setState({
			showHighlighted:false
		})
	}


	getAlbumJSX = (photo, key) => {
		console.log(photo)
		return (<div key={key}  className="photo-gallery-image-wrapper" onClick={ (event) => this.highlightImage(key)}>	
				<Img 
		           		fluid={photo.node.childImageSharp.fluid1}
		               	alt="" />
		    </div>
		)

	}

	changeHighlightedPhoto = (amount) => {
		let currentIndex = this.state.highlightedPhoto

		if (currentIndex === 0 && amount < 0) {
			currentIndex =  this.props.data.length
		} else if (currentIndex === this.props.data.length-1 && amount > 0){
			currentIndex = -1;
		}

		this.setState({
			highlightedPhoto:currentIndex+amount
		})
	}


	render(){
		let albumJSX = this.props.data.map((album,key) => this.getAlbumJSX(album,key))

		let highlightedPhoto = <div></div>
		if(typeof this.props.data[this.state.highlightedPhoto]  !== 'undefined'){
			highlightedPhoto = <img className='highlighted-image' src={this.props.data[this.state.highlightedPhoto].node.childImageSharp.fluid2.src} alt="" onClick={(event) =>{event.stopPropagation();}}/>
		}




		return(
			<div className={this.state.showHighlighted ? 'no-scroll':''}>
				<div className="album-photos">{albumJSX}</div>

				<div className={this.state.showHighlighted ? 'highlight-image-wrapper':'hidden-input'} onClick={(e) => {this.setState({showHighlighted:false})}}>
					<ArrowBackIcon fontSize="inherit" className="back-to-photos" onClick={ (event) => {event.stopPropagation();this.unHighlightImage()}}/>

					<ArrowBackIosTwoToneIcon fontSize="inherit"className="change-highlight-photo" onClick={ (event) => {event.stopPropagation();this.changeHighlightedPhoto(-1)}}/>

					<div className="highlight-image">
						<div className="highlight-image-bounds">
							{highlightedPhoto}
						</div>
						
				    </div>
				    <ArrowFwdIosTwoToneIcon fontSize="inherit" className="change-highlight-photo" onClick={ (event) => {event.stopPropagation();this.changeHighlightedPhoto(1)}}/>

				</div>
		    </div>
		)
	}
}

export default PhotoGrid  

// /						
