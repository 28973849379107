import React, { Component } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import Img from "gatsby-image"

import PhotoGrid from "./PhotoGrid"

function Album (props) {
	console.log(props)

	let albumName = props.input.substring(props.input.lastIndexOf("/"))


	const query = graphql`
	     query {
        allFile(sort: {fields: modifiedTime, order: DESC}, filter: {relativePath: {glob: "photo-gallery/*/*"}}) {
          edges {
            node {
              id
              name
              relativeDirectory
              modifiedTime,
              publicURL,
              childImageSharp {
                  fluid1: fluid(maxWidth: 400, maxHeight: 375) {
                    ...GatsbyImageSharpFluid
                  }
                  fluid2: fluid(maxWidth:1500) {
                    src
                  }
                }
            }
          }
        }
      }
  `


	let data = useStaticQuery(query)

	data = data.allFile.edges.filter(function(el){


		let rp = el.node.relativeDirectory
		rp = rp.substring(rp.lastIndexOf("/"))
		return encodeURI(rp) === albumName
	})


	return(
		<div className="album-photos"><PhotoGrid data={data} /></div>
	)
}

export default Album  