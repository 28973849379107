import React, { Component } from "react"
import { Link } from "gatsby"
import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import HomeBody from "../../components/home/HomeBody"
import Footer from "../../components/footer/Footer"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/donate.css"
import ReactModal from "react-modal"
import Img from "gatsby-image"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


import AlbumPhotos from './AlbumPhotos.js'

class PhotoAlbums extends Component {
	constructor(props){
		super(props)
	}
	

	render() {
		console.log(this.props)
		let albumName = this.props.path.substring(this.props.path.lastIndexOf("/")+1)
		albumName =  decodeURI(albumName)
		return( <div>
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>

			<h1 className="album-title">{albumName}</h1>
			<Link to="/about-us/photo-gallery" className="back-to-albums"><ArrowBackIcon /> Back to Albums</Link>
			<AlbumPhotos input ={this.props.path}/>
			<Footer />
		</div>
		)
	}
	
	

}
export default PhotoAlbums 